<template>
    <div class="filter-breadcrumb forms-filter-breadcrumb" v-if="header">
            <span v-if="header && header.cur_year" class="item-breadcrumb" @click="openFilterByRef('curYearRef')">
                {{ header.cur_year }}-{{ header.cur_year + 2 }}
            </span>
        <span v-if="header && header.year" class="item-breadcrumb" @click="openFilterByRef('yearRef')">
                {{ header.year }}
            </span>
        <span v-if="dataTypeFilter" class="item-breadcrumb" @click="openFilterByRef('dataTypeRef')">
                {{ dataTypeFilter[localLableField] }}
            </span>
        <span v-if="variantNameLoc" class="item-breadcrumb" @click="openFilterByRef('budgetVersionRef')">
                {{ variantNameLoc }}
            </span>
        <span v-if="header && header.abp" class="item-breadcrumb" @click="openFilterByRef('abpRef')">
                {{ `${header.abp} - ${getLocTitle('abp')}` }}
            </span>
        <span v-if="header.mode === 'gu' && header && header.gu"  class="item-breadcrumb" @click="openFilterByRef('guRef')">
                {{ `${header.gu} - ${getLocTitle('gu')}` }}
            </span>
        <span v-if="header.mode === 'gkkp' && header && header.gkkp"  class="item-breadcrumb" @click="openFilterByRef('gkkpRef')">
                {{ `${header.gkkp} - ${getLocTitle('gkkp')}` }}
            </span>
        <span v-if="header && header.prg" class="item-breadcrumb" @click="openFilterByRef('prgRef')">
                {{ `${header.prg} - ${getLocTitle('prg_shrt')}` }}
            </span>
        <span v-if="header && header.ppr" class="item-breadcrumb" @click="openFilterByRef('pprRef')">
                {{ `${header.ppr} - ${getLocTitle('ppr_shrt')}` }}
            </span>
        <span 
            v-if="header && header.spfName && header.spfName[localLableField]"
            class="item-breadcrumb"
            :title="header.spfName[localLableField].length > 60 ? header.spfName[localLableField] : null"
            @click="openFilterByRef('specRef')">
                {{ header.spfName[localLableField] | length60 }}
            </span>
        <span 
            v-if="header && header.formName && header.formName[localLableField]"
            class="item-breadcrumb"  
            :title="header.formName[localLableField].length > 60 ? header.formName[localLableField] : null"
            @click="openFilterByRef('formRef')">
                {{ header.formName[localLableField] | length60 }}
            </span>
        
    </div>
</template>

<script>
export default {
    name: 'breadcrumbs-filter',
    props: {
        header: Object,
        dataTypeFilter: Object,
        variantName: Object
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        },
        getLocTitle(field) {
            const mainTextFieldName = "modules.budget_request.filter." + field;
            return this.$t(mainTextFieldName);
        }
    },
    filters: {
        length60: function(value) {
            if (!value) return '';
            value = value.toString();
            const newVal = value.length >= 60 ? `${value.substring(0, 60)}...` : value;
            return newVal;
        }
    },
    computed: {
        lng() {
            let lng = 'ru';
            if (this.$i18n.locale === 'kk') lng = 'kk';
            return lng;
        },
        localLableField() {
            return `lable_name_${this.lng}`;
        },
        variantNameLoc() {
            return this.variantName ? this.variantName[`name_${this.lng}`] : null;
        }
    }
};
</script>

<style scoped>
.forms-filter-breadcrumb {
    display: inline-block;
    height: fit-content;
}
</style>