export default {

    computed: {
        lng() {
            let lng = 'ru';
            if (this.$i18n.locale === 'kk') lng = 'kk';
            return lng;
        },
    },
    data() {
        return {
            categoryFiles: []
        }
    },

    methods: {
        getLocText(field, extra = null) {
            const link = 'modules.budget_request.files_updown.';
            return this.getText(link, field, extra)
        },

        getText(link, field, extra = null) {
            const mainTextFieldName = link + field;
            if (extra) {
                if (typeof extra === 'number') return this.$tc(mainTextFieldName, extra)
                else return this.$t(mainTextFieldName, extra);
            };
            return this.$t(mainTextFieldName);
        },

        getFileTypeByCode(code) {
            const currType = this.categoryFiles.find(item => item.code === code);
            if (currType && currType.name) return currType.name;
            return '';
        },

        async loadDict() {
            try {
                const allFiles = { value: null, disabled: true };
                Object.defineProperty(allFiles, 'name', {
                    get: () => {
                        if (this.lng) return this.getLocText('select_cat');
                        else this.getLocText('select_cat');
                    }
                });
                const catFiles = [allFiles];
                

                const response = await fetch('/api-py/dictionary/files_type/');
                if (response.status === 200) {
                    const items = await response.json();
                    for (const row of items) {
                        const el = {...row};
                        this.$set(el, 'value', row.code);
                        Object.defineProperty(el, 'name', {
                            get: () => {
                                return el[`name_${this.lng}`];
                            }
                        });
                        catFiles.push(el);
                    }                    
                    this.$set(this, 'categoryFiles', catFiles);
                    
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadTModels()`, error.toString());
            }
        },

        setAllCat() {
            const allCategories = {
                name: this.getFmText('all_cats'),
                value: 'All'
            };
            this.categoryFiles[0] = allCategories;
        },
    }
}